import React, { useEffect, useState } from "react";
import { db } from "../firebase/firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const Profile = ({ user, isDarkMode }) => {
  const [userData, setUserData] = useState({
    email: "",
    display_name: "",
    phone_num: "",
    photo_url: "",
  });
  const [loading, setLoading] = useState(true);
  const [phoneError, setPhoneError] = useState("");
  const [photoUrlError, setPhotoUrlError] = useState("");
  const [generalError, setGeneralError] = useState("");

  useEffect(() => {
    console.log("Profile component mounted");
    if (user) {
      console.log("User is available:", user);
      const fetchUserData = async () => {
        try {
          const userDoc = await getDoc(doc(db, "user_profile", user.uid));
          if (userDoc.exists()) {
            console.log("User data fetched:", userDoc.data());
            setUserData(userDoc.data());
          } else {
            console.error("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchUserData();
    } else {
      console.log("User is not available");
      setLoading(false);
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    return phoneNumberObj && phoneNumberObj.isValid();
  };

  const validatePhotoURL = (url) => {
    const urlPattern = /^(https?:\/\/.*\.(?:png|jpg|jpeg|gif|svg|webp))$/i;
    return urlPattern.test(url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPhoneError("");
    setPhotoUrlError("");
    setGeneralError("");

    if (userData.phone_num && !validatePhoneNumber(userData.phone_num)) {
      setPhoneError(
        "Invalid phone number format. Examples: +1 123-456-7890, +44 20 1234 5678"
      );
      return;
    }
    if (userData.photo_url && !validatePhotoURL(userData.photo_url)) {
      setPhotoUrlError(
        "Invalid photo URL format. Please provide a valid image URL."
      );
      return;
    }
    try {
      await updateDoc(doc(db, "user_profile", user.uid), userData);
      console.log("User data updated in Firestore:", userData); // Log updated user data
    } catch (error) {
      console.error("Error updating user data:", error);
      setGeneralError("An error occurred while updating your profile.");
    }
  };

  if (loading) {
    console.log("Loading...");
    return <div>Loading...</div>;
  }

  console.log("Rendering Profile component");

  return (
    <div className={`card p-4 ${isDarkMode ? "bg-dark text-white" : ""}`}>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-control"
            value={userData.email}
            onChange={handleChange}
            readOnly
          />
        </div>
        <div className="mb-3">
          <label htmlFor="display_name" className="form-label">
            Display Name
          </label>
          <input
            type="text"
            id="display_name"
            name="display_name"
            className="form-control"
            value={userData.display_name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="phone_num" className="form-label">
            Phone Number
          </label>
          <input
            type="text"
            id="phone_num"
            name="phone_num"
            className="form-control"
            value={userData.phone_num}
            onChange={handleChange}
          />
          {phoneError && <p className="text-danger mt-1">{phoneError}</p>}
        </div>
        <div className="mb-3">
          <label htmlFor="photo_url" className="form-label">
            Photo URL
          </label>
          <input
            type="text"
            id="photo_url"
            name="photo_url"
            className="form-control"
            value={userData.photo_url}
            onChange={handleChange}
          />
          {photoUrlError && <p className="text-danger mt-1">{photoUrlError}</p>}
        </div>
        <button type="submit" className="btn btn-primary w-100">
          Update Profile
        </button>
        {generalError && <p className="text-danger mt-3">{generalError}</p>}
      </form>
    </div>
  );
};

export default Profile;
