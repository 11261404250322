import React from "react";
import Login from "../components/Login";

const LoginPage = ({ setUser, isDarkMode }) => {
  const isLoginEnabled = process.env.REACT_APP_ENABLE_LOGIN === "true";

  if (!isLoginEnabled) {
    return (
      <div
        className={`container mt-5 ${isDarkMode ? "bg-dark text-white" : ""}`}
      >
        <h2 className="text-center">Login is currently disabled</h2>
        <p className="text-center">
          Please join the waitlist to get notified when login is enabled.
        </p>
      </div>
    );
  }

  return (
    <div className={`container mt-5 ${isDarkMode ? "bg-dark text-white" : ""}`}>
      <h2 className="text-center">Login</h2>
      <Login setUser={setUser} isDarkMode={isDarkMode} />
    </div>
  );
};

export default LoginPage;
