import React from "react";
import WaitlistForm from "../components/WaitlistForm";

const WaitlistPage = ({ setUser, isDarkMode }) => {
  return (
    <div className={`container mt-5 ${isDarkMode ? "bg-dark text-white" : ""}`}>
      <h2 className="text-center">Join Our Waitlist</h2>
      <WaitlistForm setUser={setUser} isDarkMode={isDarkMode} />
    </div>
  );
};

export default WaitlistPage;
