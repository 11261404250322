import React, { useState, useEffect, useCallback } from "react";
import { Route, Routes, Link, useNavigate } from "react-router-dom";
import "./App.css";
import { FaBars } from "react-icons/fa";
import CardForm from "./components/CardForm";
import Spinner from "./components/Spinner";
import ResponseSection from "./components/ResponseSection";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import ProfilePage from "./pages/ProfilePage";
import Sidebar from "./components/Sidebar";
import { auth, db } from "./firebase/firebaseConfig.js";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  fetchCardInfoFromMongoDB,
  fetchCardInfoFromExternalApiAndLLM,
  saveCardInfoToMongoDB,
  fetchCardInfoByPerksFromExternalApiAndLLM,
} from "./api/ApiServices.js";
import TagManager from "react-gtm-module";
import WaitlistPage from "./pages/WaitlistPage";
import ReferralPage from "./pages/ReferralPage";

const App = () => {
  const [issuer, setIssuer] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardName, setCardName] = useState("");
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [benefit, setBenefit] = useState("");
  const [searchOption, setSearchOption] = useState("");
  const [user, setUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const gtmId = process.env.REACT_APP_GTM_ID || "GTM-NTQJK53P"; // Default to production ID
    const tagManagerArgs = {
      gtmId,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const handleSubmitFindCardByBenefit = useCallback(
    async (perk) => {
      setLoading(true);
      setError("");
      console.log("Benefit search submitted:", perk);
      try {
        const payload = { perk: `${perk.trim()}` };
        const llmResponse = await fetchCardInfoByPerksFromExternalApiAndLLM(
          payload
        );
        if (llmResponse) {
          setResponse({ ...llmResponse, searchOption: "benefit" });
          await saveCardInfoToMongoDB({
            issuer,
            cardType,
            cardName,
            summary: llmResponse.summary,
            summaryOfLinks: llmResponse["summaryOfLinks"],
            countAndCostList: llmResponse["countAndCostList"],
            userInput: llmResponse.userInput,
          });
        }
      } catch (error) {
        console.error("Error during the card info submission:", error);
        setError("An error occurred while processing your request.");
      } finally {
        setLoading(false);
      }
    },
    [issuer, cardType, cardName]
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const searchOption = params.get("searchOption");
    const issuer = params.get("issuer");
    const cardType = params.get("cardType");
    const cardName = params.get("cardName");
    const benefit = params.get("benefit");
    const autoSubmit = params.get("autoSubmit");

    if (searchOption) setSearchOption(searchOption);
    if (issuer) setIssuer(issuer);
    if (cardType) setCardType(cardType);
    if (cardName) setCardName(cardName);
    if (benefit) setBenefit(benefit);

    if (autoSubmit === "true") {
      if (searchOption === "card" && (issuer || cardType || cardName)) {
        handleSubmit(issuer, cardType, cardName);
      } else if (searchOption === "benefit" && benefit) {
        handleSubmitFindCardByBenefit(benefit);
      }
    }
  }, [handleSubmitFindCardByBenefit]);

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    setIsDarkMode(darkModeMediaQuery.matches);
    const handleChange = (e) => setIsDarkMode(e.matches);
    darkModeMediaQuery.addEventListener("change", handleChange);
    return () => darkModeMediaQuery.removeEventListener("change", handleChange);
  }, []);

  useEffect(() => {
    document.body.classList.toggle("dark-mode", isDarkMode);
  }, [isDarkMode]);

  const handleToggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  const handleSubmit = async (issuer, cardType, cardName) => {
    setLoading(true);
    setError("");
    try {
      const mongoDBResponse = await fetchCardInfoFromMongoDB(
        issuer,
        cardType,
        cardName
      );
      if (mongoDBResponse && mongoDBResponse.status_code === 200) {
        setResponse({ ...mongoDBResponse, searchOption: "card" });
      } else {
        const payload = {
          card_name: `${issuer.trim()} ${cardType.trim()} ${cardName.trim()}`,
        };
        console.log("Payload:", payload);
        const llmResponse = await fetchCardInfoFromExternalApiAndLLM(payload);
        if (llmResponse) {
          setResponse({ ...llmResponse, searchOption: "card" });
          await saveCardInfoToMongoDB({
            issuer,
            cardType,
            cardName,
            summary: llmResponse.summary,
            summaryOfLinks: llmResponse["summaryOfLinks"],
            countAndCostList: llmResponse["countAndCostList"],
            userInput: llmResponse.userInput,
          });
        }
      }
    } catch (error) {
      console.error("Error during the card info submission:", error);
      setError("An error occurred while processing your request.");
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setUser(null);
      // Clear local storage and cookies if necessary
      localStorage.clear();
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      navigate("/"); // Redirect to home page after sign out
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(db, "user_profile", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          let referralLink = userData.referralLink;
          if (!referralLink) {
            referralLink = `https://PerksFOMO.com/waitlist?ref=${user.uid}`;
            await updateDoc(doc(db, "user_profile", user.uid), {
              referralLink,
            });
          }
          setUser({
            ...user,
            displayName: userData.display_name,
            email: userData.email,
            phoneNumber: userData.phone_num,
            photoURL: userData.photo_url,
            isWaitlisted: userData.isWaitlisted,
            referralLink,
          });
        } else {
          setUser(user);
        }
        if (
          window.location.pathname === "/login" ||
          window.location.pathname === "/signup"
        ) {
          navigate("/");
        }
      } else {
        setUser(null);
      }
      console.log("User state changed:", user);
    });
    return () => unsubscribe();
  }, [navigate]);

  console.log("Rendering App component");

  const isSignupEnabled = process.env.REACT_APP_ENABLE_SIGNUP === "true";
  const isLoginEnabled = process.env.REACT_APP_ENABLE_LOGIN === "true";

  return (
    <div className={`app-container ${isDarkMode ? "dark-mode" : ""}`}>
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        handleSignOut={handleSignOut}
        isDarkMode={isDarkMode}
        handleToggleDarkMode={handleToggleDarkMode}
        user={user}
      />
      <header className="app-header text-center mb-4">
        <button className="btn btn-link menu-toggle" onClick={toggleSidebar}>
          <FaBars size={24} />
        </button>
        <h1>Welcome to Perks FOMO</h1>
        {!user && (
          <div className="auth-links mt-3">
            {isLoginEnabled && (
              <Link to="/login" className="btn btn-primary me-2">
                Login
              </Link>
            )}
            {isSignupEnabled && (
              <Link to="/signup" className="btn btn-secondary me-2">
                Signup
              </Link>
            )}
            <Link to="/waitlist" className="btn btn-info">
              Join Waitlist
            </Link>
          </div>
        )}
      </header>
      <main className={`app-main ${isDarkMode ? "dark-mode" : ""}`}>
        <Routes>
          <Route
            path="/login"
            element={<LoginPage setUser={setUser} isDarkMode={isDarkMode} />}
          />
          <Route
            path="/signup"
            element={<SignupPage setUser={setUser} isDarkMode={isDarkMode} />}
          />
          <Route
            path="/profile"
            element={<ProfilePage user={user} isDarkMode={isDarkMode} />}
          />
          <Route
            path="/waitlist"
            element={<WaitlistPage setUser={setUser} isDarkMode={isDarkMode} />}
          />
          <Route
            path="/referral"
            element={<ReferralPage user={user} isDarkMode={isDarkMode} />}
          />
          console.log("User state changed:", user);
          <Route
            path="/"
            element={
              user ? (
                user.isWaitlisted ? (
                  <div
                    className={`text-center mt-5 ${
                      isDarkMode ? "bg-dark text-white" : ""
                    }`}
                  >
                    <h2>Your account is on the waitlist</h2>
                    <p>
                      Thank you for joining our waitlist! We will notify you
                      once your account is approved and you can start using the
                      app.
                    </p>
                    <p>
                      Want to get approved faster? Invite your friends and
                      family to join the waitlist using your unique referral
                      link. The more people you refer, the higher your chances
                      of getting early access to our app!
                    </p>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        value={user.referralLink || ""}
                        readOnly
                      />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          navigator.clipboard.writeText(user.referralLink);
                          alert("Referral link copied to clipboard!");
                        }}
                      >
                        Copy Link
                      </button>
                    </div>
                    <p>
                      Stay tuned for updates and thank you for your support!
                    </p>
                  </div>
                ) : (
                  <>
                    <CardForm
                      issuer={issuer}
                      cardType={cardType}
                      cardName={cardName}
                      setIssuer={setIssuer}
                      setCardType={setCardType}
                      setCardName={setCardName}
                      handleSubmit={handleSubmit}
                      loading={loading}
                      benefit={benefit}
                      setBenefit={setBenefit}
                      handleSubmitFindCardByBenefit={
                        handleSubmitFindCardByBenefit
                      }
                      isDarkMode={isDarkMode}
                      setResponse={setResponse}
                      searchOption={searchOption}
                      setSearchOption={setSearchOption}
                    />
                    {loading && <Spinner />}
                    {error && <p className="text-danger">{error}</p>}
                    {response && (
                      <ResponseSection
                        response={response}
                        isDarkMode={isDarkMode}
                      />
                    )}
                  </>
                )
              ) : (
                <div className="text-center mt-5">
                  <h2>Please login or signup to continue</h2>
                  <p>
                    Join Perks FOMO to unlock exclusive benefits and rewards
                    tailored just for you!
                  </p>
                  <p>
                    Discover the best credit card perks, compare offers, and
                    make informed decisions with ease.
                  </p>
                  <p>
                    Our platform provides personalized recommendations based on
                    your preferences and spending habits.
                  </p>
                  <p>
                    Don't miss out on the opportunity to maximize your rewards
                    and save money!
                  </p>
                  <p>Sign up now and start enjoying the perks you deserve.</p>
                  <div className="auth-links mt-3">
                    {isLoginEnabled && (
                      <Link to="/login" className="btn btn-primary me-2">
                        Login
                      </Link>
                    )}
                    {isSignupEnabled && (
                      <Link to="/signup" className="btn btn-secondary me-2">
                        Signup
                      </Link>
                    )}
                    <Link to="/waitlist" className="btn btn-info">
                      Join Waitlist
                    </Link>
                  </div>
                </div>
              )
            }
          />
        </Routes>
      </main>
    </div>
  );
};

export default App;
