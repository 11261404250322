import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";

const ReferralPage = ({ user, isDarkMode }) => {
  const [referralLink, setReferralLink] = useState("");
  const [referrals, setReferrals] = useState(0);
  const [referredUsers, setReferredUsers] = useState([]);

  useEffect(() => {
    const fetchReferralData = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, "user_profile", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setReferralLink(userData.referralLink);
          setReferrals(userData.referrals);
          setReferredUsers(userData.referredUsers);
        }
      }
    };
    fetchReferralData();
  }, [user]);

  const handleShare = () => {
    navigator.clipboard.writeText(referralLink);
    alert("Referral link copied to clipboard!");
  };

  return (
    <div className={`container mt-5 ${isDarkMode ? "bg-dark text-white" : ""}`}>
      <h2 className="text-center">Invite Friends and Family</h2>
      <div className="card p-4">
        <p>Share your referral link to invite friends and family:</p>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            value={referralLink}
            readOnly
          />
          <button className="btn btn-primary" onClick={handleShare}>
            Copy Link
          </button>
        </div>
        <p>You have referred {referrals} people.</p>
        <ul>
          {referredUsers.map((userId) => (
            <li key={userId}>{userId}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ReferralPage;
