import React from "react";
import Signup from "../components/Signup";

const SignupPage = ({ setUser, isDarkMode }) => {
  const isSignupEnabled = process.env.REACT_APP_ENABLE_SIGNUP === "true";

  if (!isSignupEnabled) {
    return (
      <div
        className={`container mt-5 ${isDarkMode ? "bg-dark text-white" : ""}`}
      >
        <h2 className="text-center">Signup is currently disabled</h2>
        <p className="text-center">
          Please join the waitlist to get notified when signup is enabled.
        </p>
      </div>
    );
  }

  return (
    <div className={`container mt-5 ${isDarkMode ? "bg-dark text-white" : ""}`}>
      <h2 className="text-center">Signup</h2>
      <Signup setUser={setUser} isDarkMode={isDarkMode} />
    </div>
  );
};

export default SignupPage;
