import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Profile from "../components/Profile";

const ProfilePage = ({ user, isDarkMode }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <div className={`container mt-5 ${isDarkMode ? "bg-dark text-white" : ""}`}>
      <h2 className="text-center">Profile</h2>
      {user ? (
        <Profile user={user} isDarkMode={isDarkMode} />
      ) : (
        <p>Loading user data...</p>
      )}
    </div>
  );
};

export default ProfilePage;
