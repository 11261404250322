import React, { useState } from "react";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";
import {
  getDoc,
  setDoc,
  doc,
  query,
  where,
  collection,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import handleFirebaseError from "../firebase/firebaseErrorHandler";
import { validateEmail } from "../utils/shareUtils";

const Login = ({ setUser, isDarkMode }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [resetError, setResetError] = useState("");
  const [resetSuccess, setResetSuccess] = useState("");
  const [showResetForm, setShowResetForm] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user);
    } catch (error) {
      setError(handleFirebaseError(error));
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userDoc = await getDoc(doc(db, "user_profile", user.uid));
      if (!userDoc.exists()) {
        await setDoc(doc(db, "user_profile", user.uid), {
          display_name: user.displayName,
          email: user.email,
          phone_num: user.phoneNumber || "",
          photo_url: user.photoURL || "",
          isWaitlisted: false,
        });
      }
      setUser(user);
    } catch (error) {
      setError(handleFirebaseError(error));
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (!validateEmail(resetEmail)) {
      setResetError("Invalid email address.");
      setResetSuccess("");
      return;
    }
    try {
      const userQuery = query(
        collection(db, "user_profile"),
        where("email", "==", resetEmail)
      );
      const querySnapshot = await getDocs(userQuery);
      if (querySnapshot.empty) {
        setResetError("Email not found.");
        setResetSuccess("");
        return;
      }

      const userDoc = querySnapshot.docs[0];
      if (userDoc.exists() && userDoc.data().isWaitlisted) {
        setResetError(
          "You are on the waitlist and cannot reset your password until you receive an invitation."
        );
        setResetSuccess("");
      } else {
        await sendPasswordResetEmail(auth, resetEmail);
        setResetSuccess("Password reset email sent successfully.");
        setResetError("");
      }
    } catch (error) {
      setResetError(handleFirebaseError(error));
      setResetSuccess("");
    }
  };

  return (
    <div className={`card p-4 ${isDarkMode ? "bg-dark text-white" : ""}`}>
      <button onClick={handleGoogleLogin} className="btn btn-danger w-100 mb-3">
        Login with Google
      </button>
      <div className="d-flex align-items-center my-3">
        <hr className="flex-grow-1" />
        <span className="mx-2">OR</span>
        <hr className="flex-grow-1" />
      </div>
      <form onSubmit={handleLogin}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            id="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </div>
        <button type="submit" className="btn btn-primary w-100">
          Login with Email
        </button>
        {error && <div className="alert alert-danger mt-3">{error}</div>}
      </form>
      <div className="mt-3">
        <button
          className="btn btn-link"
          onClick={() => setShowResetForm(!showResetForm)}
        >
          Forgot Password?
        </button>
        {showResetForm && (
          <form onSubmit={handlePasswordReset}>
            <div className="mb-3">
              <label htmlFor="resetEmail" className="form-label">
                Enter your email to reset password
              </label>
              <input
                type="email"
                id="resetEmail"
                className="form-control"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                placeholder="Email"
              />
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Reset Password
            </button>
            {resetError && (
              <div className="alert alert-danger mt-3">{resetError}</div>
            )}
            {resetSuccess && (
              <div className="alert alert-success mt-3">{resetSuccess}</div>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
