export const generateShareableURL = (
  searchOption,
  issuer,
  cardType,
  cardName,
  benefit
) => {
  const baseUrl = window.location.origin;
  const params = new URLSearchParams({
    searchOption,
    issuer,
    cardType,
    cardName,
    benefit,
    autoSubmit: "true", // Ensure autoSubmit is a string "true"
  }).toString();
  return `${baseUrl}?${params}`;
};

export const generateEmailContent = (
  searchOption,
  issuer,
  cardType,
  cardName,
  benefit
) => {
  const url = generateShareableURL(
    searchOption,
    issuer,
    cardType,
    cardName,
    benefit
  );
  return `Check out these credit card perks!
    \n\nSearch Option: ${searchOption}
    \nIssuer: ${issuer}
    \nCard Type: ${cardType}
    \nCard Name: ${cardName}
    \nBenefit: ${benefit}
    \n\nClick the link to see more details: ${url}
  `;
};

export const generateSMSContent = (
  searchOption,
  issuer,
  cardType,
  cardName,
  benefit
) => {
  const url = generateShareableURL(
    searchOption,
    issuer,
    cardType,
    cardName,
    benefit
  );
  return `Check out these credit card perks! Click the link to see more details: ${url}`;
};

export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};
