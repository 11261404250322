const handleFirebaseError = (error) => {
  let errorMessage = "An unknown error occurred.";
  switch (error.code) {
    case "auth/invalid-email":
      errorMessage = "Invalid email address.";
      break;
    case "auth/user-disabled":
      errorMessage = "This user has been disabled.";
      break;
    case "auth/user-not-found":
      errorMessage = "No user found with this email.";
      break;
    case "auth/wrong-password":
      errorMessage = "Incorrect password.";
      break;
    case "auth/email-already-in-use":
      errorMessage = "This email is already in use.";
      break;
    case "auth/operation-not-allowed":
      errorMessage = "Operation not allowed.";
      break;
    case "auth/weak-password":
      errorMessage = "Password is too weak.";
      break;
    case "auth/missing-password":
      errorMessage = "Password is required.";
      break;
    case "auth/invalid-credential":
      errorMessage = "Invalid credentials provided.";
      break;
    case "auth/account-exists-with-different-credential":
      errorMessage = "An account already exists with a different credential.";
      break;
    case "auth/invalid-verification-code":
      errorMessage = "Invalid verification code.";
      break;
    case "auth/invalid-verification-id":
      errorMessage = "Invalid verification ID.";
      break;
    case "auth/missing-email":
      errorMessage = "Email is required.";
      break;
    case "auth/missing-phone-number":
      errorMessage = "Phone number is required.";
      break;
    case "auth/invalid-phone-number":
      errorMessage = "Invalid phone number.";
      break;
    case "auth/credential-already-in-use":
      errorMessage = "This credential is already in use.";
      break;
    case "auth/requires-recent-login":
      errorMessage = "Please log in again to perform this action.";
      break;
    case "auth/too-many-requests":
      errorMessage = "Too many requests. Please try again later.";
      break;
    case "auth/network-request-failed":
      errorMessage = "Network request failed. Please check your connection.";
      break;
    case "auth/popup-closed-by-user":
      errorMessage =
        "The popup was closed by the user before completing the sign-in.";
      break;
    case "auth/cancelled-popup-request":
      errorMessage = "The popup request was cancelled.";
      break;
    case "auth/app-not-authorized":
      errorMessage =
        "This app is not authorized to use Firebase Authentication.";
      break;
    case "auth/unauthorized-domain":
      errorMessage =
        "This domain is not authorized to use Firebase Authentication.";
      break;
    case "auth/invalid-api-key":
      errorMessage = "Invalid API key.";
      break;
    case "auth/app-deleted":
      errorMessage = "This app has been deleted.";
      break;
    case "auth/invalid-user-token":
      errorMessage = "Invalid user token.";
      break;
    case "auth/user-token-expired":
      errorMessage = "User token has expired.";
      break;
    case "auth/null-user":
      errorMessage = "No user is signed in.";
      break;
    // ...handle other error codes...
    default:
      errorMessage = error.message;
  }
  return errorMessage;
};

export default handleFirebaseError;
