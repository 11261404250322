import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  getDocs,
  collection,
  updateDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDIhL4_EwTfd14cwHA_leB3vD3JZhmHjjE",
  authDomain: "perks-fomo.firebaseapp.com",
  projectId: "perks-fomo",
  storageBucket: "perks-fomo.firebasestorage.app",
  messagingSenderId: "957006916472",
  appId: "1:957006916472:web:d3b969b3379e81272ab68d",
  measurementId: "G-323FBF9DWR",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const updateExistingUsers = async () => {
  const usersCollection = collection(db, "user_profile");
  const userDocs = await getDocs(usersCollection);
  userDocs.forEach(async (doc) => {
    const userData = doc.data();
    const updates = {};
    if (userData.isWaitlisted === undefined) {
      updates.isWaitlisted = false;
    }
    if (userData.referralLink === undefined) {
      updates.referralLink = `https://PerksFOMO.com/waitlist?ref=${doc.id}`;
    }
    if (userData.referrals === undefined) {
      updates.referrals = 0;
    }
    if (userData.referredUsers === undefined) {
      updates.referredUsers = [];
    }
    if (Object.keys(updates).length > 0) {
      await updateDoc(doc.ref, updates);
    }
  });
};

updateExistingUsers();

export { auth, db };
