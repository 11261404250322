import React, { useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase/firebaseConfig";
import handleFirebaseError from "../firebase/firebaseErrorHandler";
import { validateEmail } from "../utils/shareUtils";

const Signup = ({ setUser, isDarkMode }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [error, setError] = useState("");

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError("Invalid email address.");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await setDoc(doc(db, "user_profile", user.uid), {
        display_name: displayName,
        email,
        phone_num: phoneNum || "", // Handle empty phone number
        photo_url: user.photoURL || "",
      });
      console.log("User data saved to Firestore:", {
        display_name: displayName,
        email,
        phone_num: phoneNum || "",
        photo_url: user.photoURL || "",
      }); // Log user data saved to Firestore
      setUser(user);
    } catch (error) {
      setError(handleFirebaseError(error));
    }
  };

  const handleGoogleSignup = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      await setDoc(doc(db, "user_profile", user.uid), {
        display_name: user.displayName,
        email: user.email,
        phone_num: user.phoneNumber || "",
        photo_url: user.photoURL || "",
      });
      setUser(user);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className={`card p-4 ${isDarkMode ? "bg-dark text-white" : ""}`}>
      <button
        onClick={handleGoogleSignup}
        className="btn btn-danger w-100 mb-3"
      >
        Signup with Google
      </button>
      <div className="d-flex align-items-center my-3">
        <hr className="flex-grow-1" />
        <span className="mx-2">OR</span>
        <hr className="flex-grow-1" />
      </div>
      <form onSubmit={handleSignup}>
        <div className="mb-3">
          <label htmlFor="displayName" className="form-label">
            Display Name
          </label>
          <input
            type="text"
            id="displayName"
            className="form-control"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            placeholder="Display Name"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            id="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="phoneNum" className="form-label">
            Phone Number
          </label>
          <input
            type="text"
            id="phoneNum"
            className="form-control"
            value={phoneNum}
            onChange={(e) => setPhoneNum(e.target.value)}
            placeholder="Phone Number"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </div>
        <button type="submit" className="btn btn-primary w-100">
          Signup with Email
        </button>
        {error && <p className="text-danger mt-3">{error}</p>}
      </form>
    </div>
  );
};

export default Signup;
