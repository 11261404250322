import React, { useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
} from "firebase/auth";
import {
  doc,
  setDoc,
  updateDoc,
  increment,
  arrayUnion,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebaseConfig";
import { useSearchParams } from "react-router-dom";
import handleFirebaseError from "../firebase/firebaseErrorHandler";

const WaitlistForm = ({ setUser, isDarkMode }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [referralLink, setReferralLink] = useState("");
  const [searchParams] = useSearchParams();
  const referrerId = searchParams.get("ref");

  const handleWaitlistSignup = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        "defaultPassword"
      );
      const user = userCredential.user;

      console.log("New user added to wait list:", user);

      const referralLink = `https://PerksFOMO.com/waitlist?ref=${user.uid}`;
      await setDoc(doc(db, "user_profile", user.uid), {
        display_name: name,
        email,
        isWaitlisted: true,
        referralLink,
        referrals: 0,
        referredUsers: [],
      });
      if (referrerId) {
        const referrerDoc = doc(db, "user_profile", referrerId);
        await updateDoc(referrerDoc, {
          referrals: increment(1),
          referredUsers: arrayUnion(user.uid),
        });
      }
      await sendEmailVerification(user);
      setUser({
        ...user,
        referralLink,
      });
      setReferralLink(referralLink);
      setSuccess(true);
    } catch (error) {
      setError(handleFirebaseError(error));
    }
  };

  const performGoogleWaitListSignup = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const referralLink = `https://PerksFOMO.com/waitlist?ref=${user.uid}`;
      await setDoc(doc(db, "user_profile", user.uid), {
        display_name: user.displayName,
        email: user.email,
        isWaitlisted: true,
        referralLink,
        referrals: 0,
        referredUsers: [],
      });
      if (referrerId) {
        const referrerDoc = doc(db, "user_profile", referrerId);
        await updateDoc(referrerDoc, {
          referrals: increment(1),
          referredUsers: arrayUnion(user.uid),
        });
      }
      await sendEmailVerification(user);
      setUser({
        ...user,
        referralLink,
      });
      setReferralLink(referralLink);
      setSuccess(true);
    } catch (error) {
      setError(handleFirebaseError(error));
    }
  };

  return (
    <div className={`card p-4 ${isDarkMode ? "bg-dark text-white" : ""}`}>
      {success ? (
        <div>
          <h3>Thank you for joining our waitlist!</h3>
          <p>
            We will notify you once your account is approved and you can start
            using the app.
          </p>
          <p>
            Want to get approved faster? Invite your friends and family to join
            the waitlist using your unique referral link. The more people you
            refer, the higher your chances of getting early access to our app!
          </p>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              value={referralLink}
              readOnly
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                navigator.clipboard.writeText(referralLink);
                alert("Referral link copied to clipboard!");
              }}
            >
              Copy Link
            </button>
          </div>
          <p>Stay tuned for updates and thank you for your support!</p>
        </div>
      ) : (
        <>
          <button
            onClick={performGoogleWaitListSignup}
            className="btn btn-danger w-100 mb-3"
          >
            Join Waitlist with Google
          </button>
          <div className="d-flex align-items-center my-3">
            <hr className="flex-grow-1" />
            <span className="mx-2">OR</span>
            <hr className="flex-grow-1" />
          </div>
          <form onSubmit={handleWaitlistSignup}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name (optional)
              </label>
              <input
                type="text"
                id="name"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary w-100">
              Join Waitlist with Email
            </button>
            {error && <div className="alert alert-danger mt-3">{error}</div>}
          </form>
        </>
      )}
    </div>
  );
};

export default WaitlistForm;
